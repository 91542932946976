import * as React from 'react';
import { Chip } from '@mui/material';
import { DateFormatt } from '../../functions';

function renderStatus(status) {

    const colors = {
        "not-approved": 'info', 
        "approved": 'success', 
    };

    const values = {
        "not-approved": 'No', 
        "approved": 'Yes', 
    };

    return <Chip label={values[status]} color={colors[status]} size="small" />;

};

function renderDate(value){
    const val = DateFormatt(value);
    return <Chip label={val} color='default' size="small" />;
};

export const columns = [
    {
        field: "serial-number",
        headerName: "#",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'empName', 
        headerName: 'Name', 
        flex: 1.5, 
        minWidth: 200 
    },
    {
        field: "jobTitle",
        headerName: "Type",
        minWidth: 80
    },
    {
        field: 'serviceName',
        headerName: 'Service',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
    },
    {
        field: 'clientName',
        headerName: 'Client',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
    },
    {
        field: 'projectName',
        headerName: 'Project',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
    },
    {
        field: "duty",
        headerName: "Duty",
        minWidth: 30
    },
    {
        field: "target",
        headerName: "Target",
        minWidth: 80
    },
    {
        field: 'day',
        headerName: 'Date',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 190,
        renderCell: (params) => renderDate(params.value),
    },
    {
        field: 'status',
        headerName: 'Approved',
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) => renderStatus(params.value),
    },
];