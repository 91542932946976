import dayjs from 'dayjs';

export const toDate = (mysqlDate) => {
    const day = dayjs(mysqlDate).toDate();
    return `${day.toLocaleDateString()} at ${day.toLocaleTimeString()}`
};

export function DateFormatt(dateString){
    try{
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        if(formattedDate === '01-Jan-1970') return '-';
        return formattedDate;
    }catch(e){
        console.log(e);
        return dateString;
    }
};