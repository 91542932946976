import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { mreq, team } from '../gridData';
import { useTheme } from '@emotion/react';
import { toDate } from '../../../functions';
import { ClientSearch, CustomizedDataGrid } from '../../../components';
import { Close as CloseIcon, Add as AddIcon } from '@mui/icons-material';
import { Button, Dialog, List, AppBar, Toolbar, IconButton, Typography, Slide, OutlinedInput, FormControl, ListItem, FormLabel, Box, Select, MenuItem, Backdrop, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ServiceInfo = ({serviceId, open, handleClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const update = React.useRef({
        isUpdate: false, 
        id: null, 
    });

    const foreign = React.useRef({
        empId: null, 
        empName: null, 
    });

    const [Udata, setUData] = React.useState({
        name: '', 
        num: 0, 
    });

    const [isOpen ,setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [serviceData, setServiceData] = React.useState(null);

    const [mOpen, setMOpen] = React.useState(0);
    const [tOpen, setTOpen] = React.useState(false);

    const [status, setStatus] = React.useState(false);

    const toggle = () => setIsOpen(state => !state);
    const mClose = () => setMOpen(0);
    const tClose = () => setTOpen(false);

    const handleChange = React.useCallback((value) => {
        setServiceData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setServiceData]);

    const handleUData = React.useCallback((value) => {
        setUData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setUData]);

    const clearUdata = () => {
        setUData({
            name: '', 
            num: 0, 
        });
    };

    const findAndSet = (list, id, type) => {
        clearUdata();
        if(list){
            const len = list?.length || 0;
            if(len > 0){
                for(let i = 0; i < len; i++){
                    if(list[i].id === id){
                        if(type === 'material'){
                            setUData({
                                name: list[i].item, 
                                num: list[i].quantity, 
                            });
                        }else if(type === 'team'){
                            setUData({
                                name: list[i].empName, 
                                num: list[i].time, 
                            });
                        }
                        break;
                    }
                }
            }
        }
    };

    const getServiceData = async(id) => {
        setLoading(true);
        await axios.get('/get-service-info', {
            params: {
                serviceId: id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setServiceData(res.data[0]);
            setStatus(res.data[0]?.status);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get task info. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const handleSubmit = async(e, id, props) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(e.currentTarget);
        const { materialsRequired, materialsUsed, team } = props;
        const params = {
            id: id, 
            name: data.get('sname'), 
            quantity: data.get('quantity'), 
            stype: data.get('stype'), 
            status: data.get('status'), 
            materialsRequired: materialsRequired, 
            materialsUsed: materialsUsed, 
            team: team, 
        };
        await axios.post('/update-service', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: 'Service was updated'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error updating service. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        handleClose();
    };

    const delService = React.useCallback(async(id) => {
        await axios.post('/delete-service', { id: id }, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Deleted', 
                text: 'Service was deleted'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error deleting tasks. Check browser logs for more info'
            });
            console.log()`Error: ${e}`;
        });
        handleClose();
    }, [handleClose]);

    const handleMSubmit = React.useCallback((e, type) => {
        const id = update.current.id;
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            id: Date.now(), 
            item: data.get('mname'), 
            quantity: data.get('mq'), 
        };
        if(type === 1){
            if(update.current.isUpdate){
                setServiceData(state => ({
                    ...state, 
                    materialsRequired: [...state?.materialsRequired?.map(obj => obj?.id === id ? params : obj)]
                }));
            }else{
                setServiceData(state => ({
                    ...state, 
                    materialsRequired: [params, ...(state?.materialsRequired || [])]
                }));
            }
        }else if(type === 2){
            if(update.current.isUpdate){
                setServiceData(state => ({
                    ...state, 
                    materialsUsed: [...state?.materialsUsed?.map(obj => obj?.id === id ? params : obj)]
                }));
            }else{
                setServiceData(state => ({
                    ...state, 
                    materialsUsed: [params, ...(state?.materialsUsed || [])]
                }));
            }
        }
        mClose();
        update.current.id = null;
        update.current.isUpdate = null;
    }, []);

    const handleTSubmit = React.useCallback((e) => {
        const id = update.current.id;
        e.preventDefault();
        // const data = new FormData(e.currentTarget);
        const params = {
            id: Date.now(), 
            empId: foreign.current.empId, 
            empName: foreign.current.empName, 
            time: null, // data.get('avgwrkhrs'), 
        };
        if(update.current.isUpdate){
            setServiceData(state => ({
                ...state, 
                team: [...state?.team?.map(obj => obj?.id === id ? params : obj)]
            }));
        }else{
            setServiceData(state => ({
                ...state, 
                team: [params, ...(state?.team || [])]
            }));
        }
        tClose();
        update.current.id = null;
        update.current.isUpdate = null;
    }, []);

    const deleteJSON = React.useCallback((type) => {
        const id = update.current.id;
        if(type === 'mreq'){
            setServiceData(state => ({
                ...state, 
                materialsRequired: [...state?.materialsRequired?.filter(obj => obj?.id !== id)]
            }));
        }else if(type === 'mused'){
            setServiceData(state => ({
                ...state, 
                materialsUsed: [...state?.materialsUsed?.filter(obj => obj?.id !== id)]
            }));
        }else if(type === 'team'){
            setServiceData(state => ({
                ...state, 
                team: [...state?.team?.filter(obj => obj?.id !== id)]
            }));
        }
        tClose();
        mClose();
        update.current.id = null;
        update.current.isUpdate = null;
    }, []);

    const genReport = async(id) => {
        setLoading(true);
        await axios.get('/create-team-report', {
            params: {
                serviceId: id, 
            },
            responseType: 'blob', 
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Team_Report.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to create team report. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    React.useEffect(() => {
        getServiceData(serviceId);
    }, [serviceId]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    const alerty = React.useMemo(() => (
        <Dialog open={isOpen} onClose={toggle}>
            <DialogTitle>Delete this service</DialogTitle>
            <DialogContent>
                <DialogContentText>Delete this service? This action is undoable!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => delService(serviceId)} disabled={loading}>DELETE</Button>
                <Button onClick={toggle} disabled={loading}>CANCEL</Button>
            </DialogActions>
        </Dialog>
    ), [isOpen, delService, serviceId, loading]);

    const mDialog = React.useMemo(() => (
        <Dialog open={mOpen} onClose={mClose}>
            <DialogTitle>{update.current.isUpdate ? 'Edit' : ''} Materials{mOpen === 1 ? ' required' : ' used'}</DialogTitle>
            <DialogContent>
                <Box component='form' onSubmit={e => handleMSubmit(e, mOpen)} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControl>
                        <FormLabel htmlFor="mname">Material name</FormLabel>
                        <OutlinedInput required margin="dense" id="mname" name="mname" label="Material name" placeholder="Material name" type="text" inputProps={{ maxLength: 50 }} value={Udata.name} onChange={e => handleUData({ name: e.target.value })}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="mq">Material Quantity</FormLabel>
                        <OutlinedInput required margin="dense" id="mq" name="mq" label="Quantity" placeholder="Quantity" type="number" inputProps={{ maxLength: 5 }} value={Udata.num} onChange={e => handleUData({ num: e.target.value })}/>
                    </FormControl>
                    <Box sx={{ flexDirection: 'row', mt: 2 }}>
                        <Button disabled={loading} type='submit'>{update.current.isUpdate ? 'EDIT' : 'ADD'}</Button>
                        {update.current.isUpdate ? 
                        <Button disabled={loading} onClick={() => deleteJSON(mOpen === 1 ? 'mreq' : 'mused')} >DELETE</Button> : null}
                        <Button disabled={loading} onClick={mClose} >CANCEL</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    ), [mOpen, handleMSubmit, update, deleteJSON, Udata, handleUData, loading]);

    const tDialog = React.useMemo(() => (
        <Dialog open={tOpen} onClose={tClose}>
            <DialogTitle>{update.current.isUpdate ? 'Edit' : ''} Select Employee</DialogTitle>
            <DialogContent sx={{ minWidth: 360 }}>
                <Box component='form' onSubmit={handleTSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ClientSearch label='Search Employee' placeholder='Search employee' type='employee' id='' name={Udata.name} onChange={(id, name) => {
                        foreign.current.empId = id;
                        foreign.current.empName = name;
                        handleUData({ name: name });
                    }}/>
                    {/* <FormControl sx={{ maxWidth: 280, mt: 2 }}>
                        <FormLabel htmlFor="avgwrkhrs">Avg working hours</FormLabel>
                        <OutlinedInput  margin="dense" id="avgwrkhrs" name="avgwrkhrs" label="Avg working hours" placeholder="Avg working hours" type="number" inputProps={{ maxLength: 10 }}  value={Udata.num} onChange={e => handleUData({ num: e.target.value })}/>
                    </FormControl> */}
                    <Box sx={{ flexDirection: 'row', mt: 2 }}>
                        <Button disabled={loading} type='submit'>{update.current.isUpdate ? 'EDIT' : 'ADD'}</Button>
                        {update.current.isUpdate ? 
                        <Button disabled={loading} onClick={() => deleteJSON('team')}>DELETE</Button> : null}
                        <Button disabled={loading} onClick={tClose}>CANCEL</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    ), [tOpen, handleTSubmit, update, deleteJSON, Udata, handleUData, loading]);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}
        PaperProps={{
            sx: {
              height: '650px', 
            }
        }}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Task Info</Typography>
                    <Button disabled={loading} color="error" onClick={toggle}>DELETE SERVICE</Button>
                </Toolbar>
            </AppBar>
            <Box component="form" onSubmit={(e) => handleSubmit(e, serviceId, serviceData)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <List>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="sname">Task Name</FormLabel>
                            <OutlinedInput  required margin="dense" id="sname" name="sname" label="Task Name" placeholder="Task Name" type="text" fullWidth inputProps={{ maxLength: 50 }} value={serviceData?.name} onChange={e => handleChange({ name: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="quantity">Quantity</FormLabel>
                            <OutlinedInput  required margin="dense" id="quantity" name="quantity" label="Quantity" placeholder="Quantity" type="number" fullWidth inputProps={{ maxLength: 5 }} value={serviceData?.quantity} onChange={e => handleChange({ quantity: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="stype">Task Type</FormLabel>
                            <OutlinedInput  required margin="dense" id="stype" name="stype" label="Task Type" placeholder="Task Type" type="text" fullWidth inputProps={{ maxLength: 30 }} value={serviceData?.serviceType} onChange={e => handleChange({ serviceType: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="status">Status</FormLabel>
                            <Select required id='status' name='status' label='Status' value={status} onChange={e => setStatus(e.target.value)}>
                                <MenuItem value='active'>Ongoing</MenuItem>
                                <MenuItem value='completed'>Completed</MenuItem>
                                <MenuItem value='pending'>Upcoming</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="team">Team</FormLabel>
                                <IconButton onClick={() => {
                                    clearUdata();
                                    setTOpen(true);
                                    update.current.isUpdate = false;
                                    update.current.id = null;
                                }}>
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                            <CustomizedDataGrid onClick={e => {
                                findAndSet(serviceData?.team, e, 'team');
                                setTOpen(true);
                                update.current.isUpdate = true;
                                update.current.id = e;
                            }} columns={team} rows={serviceData?.team || []}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="mreq">Materials Required</FormLabel>
                                <IconButton onClick={() => {
                                    clearUdata();
                                    setMOpen(1);
                                    update.current.isUpdate = false;
                                    update.current.id = null;
                                }}>
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                            <CustomizedDataGrid onClick={e => {
                                findAndSet(serviceData?.materialsRequired, e, 'material');
                                setMOpen(1);
                                update.current.isUpdate = true;
                                update.current.id = e;
                            }} columns={mreq} rows={serviceData?.materialsRequired || []}/>
                        </FormControl>
                        <Box width={0.02} sx={{ display: { xs: 'none', md: 'flex' } }}/>
                        <FormControl>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="mused">Materials Used</FormLabel>
                                <IconButton onClick={() => {
                                    clearUdata();
                                    setMOpen(2);
                                    update.current.isUpdate = false;
                                    update.current.id = null;
                                }}>
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                            <CustomizedDataGrid onClick={e => {
                                findAndSet(serviceData?.materialsUsed, e, 'material');
                                setMOpen(2);
                                update.current.isUpdate = true;
                                update.current.id = e;
                            }} columns={mreq} rows={serviceData?.materialsUsed || []}/>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl fullWidth>
                            <FormLabel>Created at: {toDate(serviceData?.createdAt)}</FormLabel>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel>Last changed: {toDate(serviceData?.lastChanged)}</FormLabel>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <Button disabled={loading} type='submit' color="inherit" variant='outlined' fullWidth sx={{ mr: 2, ml: 2 }}>UPDATE</Button>
                    </ListItem>
                    <ListItem>
                        <Button disabled={loading} color="inherit" variant='contained' fullWidth sx={{ m: 2, mt: 0 }} onClick={() => genReport(serviceId)}>GENERATE TEAM REPORT</Button>
                    </ListItem>
                </List>
            </Box>
            {loading ? loader : null}
            {isOpen ? alerty : null}
            {mOpen === 0 ? null : mDialog}
            {tOpen ? tDialog : null}
        </Dialog>

    );

};

export default React.memo(ServiceInfo);