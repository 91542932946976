import * as React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { columns } from './gridData';
import { useTheme } from '@emotion/react';
import { Add as AddIcon } from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClientSearch, CustomizedDataGrid } from '../../components';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Button, Dialog, List,  AppBar, Toolbar, IconButton, Typography, Slide, OutlinedInput, FormControl, ListItem, FormLabel, Box, Backdrop, CircularProgress, useMediaQuery, DialogContent, DialogTitle, Select, MenuItem } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateInvoice = ({view, invoiceId, open, handleClose}) => {

    const update = React.useRef({
        isUpdate: false, 
        id: null, 
    });

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [isOpen, setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const close = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const l1 = Date.now();
    const l2 = l1.toString().length - 6;
    const l3 = l1.toString().substring(l2);

    const [data, setData] = React.useState({
        name: '', 
        address: '', 
        phone: '', 
        vatTrn: '', 
        invoiceDate: new Date(), 
        invoiceNo: view ? 0 :  parseInt(l3), 
        lpoNo: 0, 
        lpoDate: new Date(), 
        refNo: '', 
        list: [], 
        taxableValue: 0, 
        taxAmount: 0, 
    });

    const [status, setStatus] = React.useState(false);

    const handleChange = React.useCallback((value) => {
        setData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setData]);

    const [Udata, setUData] = React.useState({
        desc: '', 
        qty: 0, 
        unitprice: 0, 
        amount: 0, 
        taxRate: 0, 
        taxAmount: 0, 
        netAmount: 0, 
    });

    const handleUData = React.useCallback((value) => {
        setUData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setUData]);

    const clearUdata = () => {
        setUData({
            desc: '', 
            qty: 0, 
            unitprice: 0, 
            amount: 0, 
            taxRate: 0, 
            taxAmount: 0, 
            netAmount: 0, 
        });
    };

    const delInvoice = async(id) => {
        if(window.confirm('Are you sure you want to delete this invoice?')){
            await axios.post('/delete-invoice', { invoiceId: id }, {
                headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
            }).then(() => {
                Swal.fire({
                    title: 'Done', 
                    text: 'Invoice was deleted'
                });
            }).catch((e) => {
                Swal.fire({
                    title: 'Error', 
                    text: 'Error deleting invoice. Check browser logs for more info'
                });
                console.log(`Error: ${e}`);
            });
            setLoading(false);
            handleClose();
        }
    };

    const downloadInvoice = async(id) => {
        setLoading(true);
        await axios.get('/create-invoice-report', {
            params: {
                invoiceId: id, 
            },
            responseType: 'blob', 
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Invoice.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to create invoice report. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const getInvoice = async(id) => {
        setLoading(true);
        await axios.get('/get-invoice-info', {
            params: {
                invoiceId: id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setData(res.data[0]);
            setStatus(res.data[0]?.status);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error getting invoice. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const handleSubmit = async(e, list, id, view) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            id: view ? id : '', 
            name: data.get('name'), 
            address: data.get('address'), 
            phone: data.get('phone'), 
            vatTrn: data.get('vat'), 
            invoiceDate: data.get('invoiceDate'), 
            invoiceNo: parseInt(data.get('invoiceNum')), 
            lpoNo: data.get('lpoNum'), 
            lpoDate: data.get('lpoDate'), 
            refNo: data.get('refnum'), 
            list: list, 
            taxableValue: data.get('taxVal'), 
            taxAmount: data.get('taxAmount'), 
            status: data.get('status'), 
            total: parseFloat(data.get('taxVal')) + parseFloat(data.get('taxAmount')), 
        };
        setLoading(true);
        await axios.post(view ? '/update-invoice' : '/create-invoice', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then(() => {
            Swal.fire({
                title: 'Done', 
                text: `Invoice was ${view ? 'updated' : 'created'}`
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: `Error ${view ? 'updating' : 'creating'} invoice. Check browser logs for more info`
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        handleClose();
    };

    const findAndSet = (list, id) => {
        update.current.isUpdate = true;
        update.current.id = id;
        clearUdata();
        setIsOpen(true);
        if(list){
            const len = list?.length || 0;
            if(len > 0){
                for(let i = 0; i < len; i++){
                    if(list[i].id === id){
                        setUData({
                            desc: list[i].desc, 
                            qty: list[i].qty, 
                            unitprice: list[i].unitprice, 
                            amount: list[i].amount, 
                            taxRate: list[i].taxRate, 
                            taxAmount: list[i].taxAmount, 
                            netAmount: list[i].netAmount, 
                        });
                        break;
                    }
                }
            }
        }
    };

    const newOpen = () => {
        update.current.isUpdate = false;
        update.current.id = null;
        clearUdata();
        setIsOpen(true);
    };

    const handleSubmit2 = React.useCallback(async(e) => {
        const id = update.current.id;
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            id: new Date(), 
            desc: data.get('desc'), 
            qty: data.get('qty'), 
            unitprice: data.get('price'), 
            amount: data.get('amount'), 
            taxRate: data.get('rate'), 
            taxAmount: data.get('taxAmount'), 
            netAmount: data.get('netAmount'), 
        };
        if(update.current.isUpdate){
            setData(state => ({
                ...state,
                list: [...state.list.map(obj => obj.id === id ? params : obj)]
            }));
        }else{
            setData(state => ({
                ...state,
                list: [params, ...state.list]
            }));
        }
        close();
    }, [close]);

    const delList = React.useCallback(() => {
        const id = update.current.id;
        setData(state => ({
            ...state,
            list: [...state.list.filter(obj => obj.id !== id)]
        }));
        close();
    }, [close]);

    React.useEffect(() => {
        if(view && invoiceId){
            getInvoice(invoiceId);
        }
    }, [view, invoiceId]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress />
        </Backdrop>
    ), []);

    const amount = React.useMemo(() => Math.round(Udata.qty) *  Udata.unitprice, [Udata.qty, Udata.unitprice]);
    const taxAmount = React.useMemo(() => (amount * Udata.taxRate) / 100, [amount, Udata.taxRate]);
    const total = React.useMemo(() => amount + taxAmount, [amount, taxAmount]);

    const mDialog = React.useMemo(() => (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{update.current.isUpdate ? 'Edit' : 'Create'}</DialogTitle>
            <DialogContent>
                <Box component='form' onSubmit={handleSubmit2} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <List>
                        <ListItem>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="desc">Description</FormLabel>
                                <OutlinedInput required margin="dense" id="desc" name="desc" label="Description" placeholder="Description" type="text" inputProps={{ maxLength: 500 }} value={Udata.desc} onChange={e => handleUData({ desc: e.target.value })}/>
                            </FormControl>
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl>
                                <FormLabel htmlFor="qty">Quantity</FormLabel>
                                <OutlinedInput required margin="dense" id="qty" name="qty" label="Quantity" placeholder="Quantity" type="number" inputProps={{ maxLength: 10 }} value={Udata.qty} onChange={e => handleUData({ qty: e.target.value })} sx={{ minWidth: '60px !important', width: '180px !important' }}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="price">Unit Price</FormLabel>
                                <OutlinedInput required margin="dense" id="price" name="price" label="Unit Price" placeholder="Unit Price" type="number" inputProps={{ maxLength: 10 }} value={Udata.unitprice} onChange={e => handleUData({ unitprice: e.target.value })} sx={{ minWidth: '60px !important' }}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="rate">Tax Rate</FormLabel>
                                <OutlinedInput required margin="dense" id="rate" name="rate" label="Tax Rate" placeholder="Tax Rate" type="number" inputProps={{ maxLength: 10 }} value={Udata.taxRate} onChange={e => handleUData({ taxRate: e.target.value })} sx={{ minWidth: '60px !important' }}/>
                            </FormControl>
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl>
                                <FormLabel htmlFor="amount">Amount</FormLabel>
                                <OutlinedInput readOnly required margin="dense" id="amount" name="amount" label="Amount" placeholder="Amount" type="number" inputProps={{ maxLength: 10 }} value={amount} sx={{ minWidth: '60px !important', width: '180px !important' }}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="taxAmount">Tax Amount</FormLabel>
                                <OutlinedInput readOnly required margin="dense" id="taxAmount" name="taxAmount" label="Tax Amount" placeholder="Tax Amount" type="number" inputProps={{ maxLength: 10 }} value={taxAmount} sx={{ minWidth: '60px !important' }}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="netAmount">Net Amount</FormLabel>
                                <OutlinedInput readOnly required margin="dense" id="netAmount" name="netAmount" label="Net Amount" placeholder="Net Amount" type="number" inputProps={{ maxLength: 10 }} value={total} sx={{ minWidth: '60px !important' }}/>
                            </FormControl>
                        </ListItem>
                    </List>
                    <Box sx={{ flexDirection: 'row', mt: 2 }}>
                        <Button type='submit' disabled={loading}>{update.current.isUpdate ? 'EDIT' : 'ADD'}</Button>
                        {update.current.isUpdate ? <Button onClick={delList} disabled={loading}>DELETE</Button> : null}
                        <Button onClick={close} disabled={loading}>CANCEL</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    ), [update, close, Udata, handleUData, handleSubmit2, delList, isOpen, amount, taxAmount, total, loading]);

    const taxableValue = React.useMemo(() =>  data.list.reduce((sum, item) => parseFloat(sum) + parseFloat(item.amount), 0), [data.list]);
    const totalTax = React.useMemo(() =>  data.list.reduce((sum, item) => parseFloat(sum) + parseFloat(item.taxAmount), 0), [data.list]);

    const totalAmount = React.useMemo(() => parseFloat(taxableValue) + parseFloat(totalTax), [taxableValue, totalTax]);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{view ? 'Invoice Info' : 'Create Invoice'}</Typography>
                    {view ? <Button color="error" disabled={loading} onClick={() => delInvoice(invoiceId)}>DELETE INVOICE</Button> : null}
                </Toolbar>
            </AppBar>
            <Box component="form" onSubmit={(e) => handleSubmit(e, data.list, invoiceId, view)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <List>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <ClientSearch label='Search clients' name={data.name} placeholder='Search clients' type='client' onChange={(x, y, z) => {
                            handleChange({
                                name: z.name, 
                                phone: z.mob1 || z.mob2, 
                            });
                        }}/>
                        <FormControl>
                            <FormLabel htmlFor="status">Status</FormLabel>
                            <Select required id='status' name='status' label='Status' value={status} onChange={e => setStatus(e.target.value)}>
                                <MenuItem value='incomplete' selected={status === 'incomplete'}>Unpaid</MenuItem>
                                <MenuItem value='complete' selected={status === 'complete'}>Paid</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="name">Name</FormLabel>
                            <OutlinedInput  required margin="dense" id="name" name="name" label="Name" placeholder="Name" type="text" fullWidth inputProps={{ maxLength: 50 }} value={data.name} onChange={e => handleChange({ name: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="phone">Phone</FormLabel>
                            <OutlinedInput  required margin="dense" id="phone" name="phone" label="Phone" placeholder="Phone" type="tel" fullWidth inputProps={{ maxLength: 15 }} value={data.phone} onChange={e => handleChange({ phone: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <OutlinedInput margin="dense" id="address" name="address" label="Address" placeholder="Address" type="text" fullWidth inputProps={{ maxLength: 200 }} value={data.address} onChange={e => handleChange({ address: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="invoiceNum">Invoice Number</FormLabel>
                            <OutlinedInput  required margin="dense" id="invoiceNum" name="invoiceNum" label="Invoice Number" placeholder="Invoice Number" type="number" fullWidth inputProps={{ maxLength: 30 }} value={data.invoiceNo} onChange={e => handleChange({ invoiceNo: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="invoiceDate">Invoice Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker required id='invoiceDate' name='invoiceDate' slotProps={{ textField: { required: true } }} value={dayjs(data.invoiceDate)} onChange={e => handleChange({ invoiceDate: e.target.value })}/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="lpoNum">LPO Number</FormLabel>
                            <OutlinedInput required margin="dense" id="lpoNum" name="lpoNum" label="LPO Number" placeholder="LPO Number" type="number" fullWidth inputProps={{ maxLength: 15 }} value={data.lpoNo} onChange={e => handleChange({ lpoNo: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="lpoDate">LPO Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker required id='lpoDate' name='lpoDate' slotProps={{ textField: { required: true } }} value={dayjs(data.lpoDate)} onChange={e => handleChange({ lpoDate: e.target.value })}/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="vat">VAT TRN</FormLabel>
                            <OutlinedInput required margin="dense" id="vat" name="vat" label="VAT TRN" placeholder="VAT TRN" type="text" fullWidth inputProps={{ maxLength: 30 }} value={data.vatTrn} onChange={e => handleChange({ vatTrn: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="refnum">Project Name</FormLabel>
                            <OutlinedInput required margin="dense" id="refnum" name="refnum" label="Project Name" placeholder="Project Name" type="text" fullWidth inputProps={{ maxLength: 100 }} value={data.refNo} onChange={e => handleChange({ refNo: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="list">List</FormLabel>
                                <IconButton onClick={newOpen}>
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                            <CustomizedDataGrid columns={columns} rows={data.list || []} onClick={e => findAndSet(data.list, e)}/>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="taxVal">Taxable Value</FormLabel>
                            <OutlinedInput readOnly required margin="dense" id="taxVal" name="taxVal" label="Taxable Value" placeholder="Taxable Value" type="number" fullWidth inputProps={{ maxLength: 10 }} value={parseFloat(taxableValue)} sx={{ minWidth: '60px !important', width: '180px !important' }}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="taxAmount">Tax Amount</FormLabel>
                            <OutlinedInput readOnly required margin="dense" id="taxAmount" name="taxAmount" label="Tax Amount" placeholder="Tax Amount" type="number" fullWidth inputProps={{ maxLength: 10 }} value={parseFloat(totalTax)} sx={{ minWidth: '60px !important', width: '180px !important' }}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="gtotal">Total Amount</FormLabel>
                            <OutlinedInput readOnly required margin="dense" id="gtotal" name="gtotal" label="Total Amount" placeholder="Total Amount" type="number" fullWidth inputProps={{ maxLength: 10 }} value={parseFloat(totalAmount)} sx={{ minWidth: '60px !important', width: '180px !important' }}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <Button disabled={loading} type='submit' color="inherit" variant='outlined' fullWidth sx={{ m: 2 }}>{view ? 'UPDATE' : 'CREATE'}</Button>
                    </ListItem>
                    {view ? 
                    <ListItem>
                        <Button disabled={loading} color="inherit" variant='outlined' sx={{ mb: 2, ml: 2, mr: 2 }} fullWidth onClick={() => downloadInvoice(invoiceId)}>DOWNLOAD INVOICE</Button>
                    </ListItem> : null}
                </List>
            </Box>
            {loading ? loader : null}
            {isOpen ? mDialog : null}
        </Dialog>

    );

};

export default React.memo(CreateInvoice);