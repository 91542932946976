import * as React from 'react';
import { Chip } from '@mui/material';
import { DateFormatt } from '../../functions';

function renderStatus(status) {

    const colors = {
        active: 'info', 
        completed: 'success', 
        pending: 'warning', 
        supervisor: 'success', 
        team_member: 'default', 
    };

    const label = {
        active: 'Ongoing', 
        completed: 'Completed', 
        pending: 'Upcoming', 
        supervisor: 'Supervisor', 
        team_member: 'Team member', 
    };

    return <Chip label={label[status]} color={colors[status]} size="small" />;

};

function renderDate(value){
    const val = DateFormatt(value);
    return <Chip label={val} color='default' size="small" />;
};

export const columns = [
    {
        field: "serial-number",
        headerName: "#",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'name', 
        headerName: 'Project Name', 
        flex: 1.5, 
        minWidth: 200 
    },
    {
        field: 'clientName',
        headerName: 'Client Name',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
    },
    {
        field: 'startDate',
        headerName: 'Started On',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 150,
        renderCell: (params) => renderDate(params.value),
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) => renderStatus(params.value),
    },
];

export const emp = [
    {
        field: "serial-number",
        headerName: "#",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'empName', 
        headerName: 'Employee Name', 
        flex: 1.5, 
        minWidth: 200, 
    },
    { 
        field: 'role', 
        headerName: 'Role', 
        flex: 1.5, 
        minWidth: 200, 
        renderCell: (params) => renderStatus(params.value),
    },
];

export const ser = [
    {
        field: "serial-number",
        headerName: "#",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'name', 
        headerName: 'Task Name', 
        flex: 1.5, 
        minWidth: 200, 
    },
];

export const duty = [
    {
        field: "serial-number",
        headerName: "#",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    {
        field: "duty",
        headerName: "Duty",
        minWidth: 40
    },
    {
        field: "target",
        headerName: "target",
        minWidth: 80
    },
    {
        field: "day",
        headerName: "Date",
        minWidth: 195,
        renderCell: (params) => renderDate(params.value),
    },
];