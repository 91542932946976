import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toDate } from '../../functions';
import { renderExpiry } from './gridData';
import { useTheme } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Button, Dialog, List,  AppBar, Toolbar, IconButton, Typography, Slide, OutlinedInput, FormControl, ListItem, FormLabel, Box, Backdrop, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Checkbox, FormControlLabel } from '@mui/material';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ClientInfo = ({clientId, open, handleClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [isOpen ,setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [clientData, setClientData] = React.useState(null);

    const toggle = () => setIsOpen(state => !state);

    const handleChange = React.useCallback((value) => {
        setClientData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setClientData]);

    const getClientData = async(id) => {
        setLoading(true);
        await axios.get('/get-client-info', {
            params: {
                clientId: id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setClientData(res.data[0]);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Cannot get client info. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const handleSubmit = async(e, id) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            id: id, 
            name: data.get('cname'), 
            status: data.get('status'), 
            clientType: data.get('ctype'), 
            email: data.get('email'), 
            mob1: data.get('ph1'), 
            mob2: data.get('ph2'), 
            comments: data.get('comments'), 
            clientCode: data.get('ccode'), 
            amcexpiry: data.get('amcexpiry'), 
            amc: data.get('amc') ? 1 : 0, 
        };
        setLoading(true);
        await axios.post('/update-client', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: 'Client was updated'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error updating client. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        handleClose();
    };

    const delClient = React.useCallback(async(id) => {
        await axios.post('/delete-client', { id: id }, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Deleted', 
                text: 'Client was deleted'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error deleting client. Check browser logs for more infp'
            });
            console.log(`Error: ${e}`);
        });
        handleClose();
    }, [handleClose]);

    React.useEffect(() => {
        getClientData(clientId);
    }, [clientId]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    const alerty = React.useMemo(() => (
        <Dialog open={isOpen} onClose={toggle}>
            <DialogTitle>Delete this client</DialogTitle>
            <DialogContent>
                <DialogContentText>Delete this client? This action is undoable!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => delClient(clientId)} disabled={loading}>DELETE</Button>
                <Button onClick={toggle} disabled={loading}>CANCEL</Button>
            </DialogActions>
        </Dialog>
    ), [isOpen, delClient, clientId, loading]);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Client Information</Typography>
                    <Button color="error" onClick={toggle}disabled={loading}>DELETE CLIENT</Button>
                </Toolbar>
            </AppBar>
            <Box component="form" onSubmit={(e) => handleSubmit(e, clientId)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <List>
                    <ListItem>
                        {renderExpiry(clientData?.amcexpiry)}
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="cname">Client Name</FormLabel>
                            <OutlinedInput  required margin="dense" id="cname" name="cname" label="Client Name" placeholder="Client Name" type="text" fullWidth inputProps={{ maxLength: 50 }} value={clientData?.name} onChange={e => handleChange({ name: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="ccode">Client Code</FormLabel>
                            <OutlinedInput  required margin="dense" id="ccode" name="ccode" label="Client Code" placeholder="Client Code" type="text" fullWidth inputProps={{ maxLength: 30 }} value={clientData?.clientCode} onChange={e => handleChange({ clientCode: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="status">Status</FormLabel>
                            <OutlinedInput  required margin="dense" id="status" name="status" label="Status" placeholder="Status" type="text" fullWidth inputProps={{ maxLength: 30 }} value={clientData?.status} onChange={e => handleChange({ status: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="ctype">Client Type</FormLabel>
                            <OutlinedInput  required margin="dense" id="ctype" name="ctype" label="Client Type" placeholder="Client Type" type="text" fullWidth inputProps={{ maxLength: 30 }} value={clientData?.clientType} onChange={e => handleChange({ clientType: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <OutlinedInput  margin="dense" id="email" name="email" label="Email" placeholder="Email" type="email" fullWidth inputProps={{ maxLength: 50 }} value={clientData?.email} onChange={e => handleChange({ email: e.target.value })}/>
                        </FormControl>
                        <FormControlLabel control={<Checkbox id='amc' name='amc' checked={clientData?.amc === 1} onChange={e => handleChange({ amc: e.target.checked ? 1 : 0 })}/>} label="AMC" sx={{ ml: { xs: 0, md: 1 }, mt: 3 }}/>
                        <FormControl>
                            <FormLabel htmlFor="amcexpiry">AMC Expiry</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker id='amcexpiry' name='amcexpiry' value={dayjs(clientData?.amcexpiry)} onChange={e => handleChange({ amcexpiry: e })}/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="ph1">Phone Number 1</FormLabel>
                            <OutlinedInput  required margin="dense" id="ph1" name="ph1" label="Phone Number 1" placeholder="Phone Number 1" type="tel" fullWidth inputProps={{ maxLength: 15 }} value={clientData?.mob1} onChange={e => handleChange({ mob1: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="ph2">Phone Number 2</FormLabel>
                            <OutlinedInput  margin="dense" id="ph2" name="ph2" label="Phone Number 2" placeholder="Phone Number 2" type="tel" fullWidth inputProps={{ maxLength: 15 }} value={clientData?.mob2} onChange={e => handleChange({ mob2: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="comments">Comments</FormLabel>
                            <OutlinedInput multiline  margin="dense" id="comments" name="comments" label="Comments" placeholder="Comments" type="text" fullWidth inputProps={{ maxLength: 500 }} value={clientData?.comments} onChange={e => handleChange({ comments: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl fullWidth>
                            <FormLabel>Created at: {toDate(clientData?.createdAt)}</FormLabel>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel>Last changed: {toDate(clientData?.lastChanged)}</FormLabel>
                        </FormControl>
                    </ListItem>
                </List>
                <Button disabled={loading} type='submit' color="inherit" variant='contained' sx={{ m: 2 }}>UPDATE</Button>
            </Box>
            {loading ? loader : null}
            {isOpen ? alerty : null}
        </Dialog>

    );

};

export default React.memo(ClientInfo);