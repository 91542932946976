import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { columns } from '../employees/gridData';
import EmployeeInfo from '../employees/EmployeeInfo';
import { CustomizedDataGrid } from '../../components';
import CreateEmployee from '../employees/CreateEmployee';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';
import { Box, Typography, Button, Backdrop, CircularProgress, IconButton } from '@mui/material';

export default function Admin() {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [empId, setEmpId] = React.useState(null);

    const [employees, setEmployees] = React.useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const clearEmpId = () => setEmpId(null);

    const getEmpData = async() => {
        setLoading(true);
        await axios.get('/get-admins', {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => setEmployees(res.data)).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get employees. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const reload = () => {
        getEmpData();
    };

    React.useEffect(() => {
        getEmpData();
    }, []);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    return (

        <Box sx={{ width: '100%', height: '100vh' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Admins & Super Admins</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconButton onClick={reload}>
                        <RefreshIcon/>
                    </IconButton>
                    <Button disabled={loading} variant="outlined" onClick={handleOpen} sx={{ ml: 2 }}>CREATE</Button>
                </Box>
            </Box>
            <CustomizedDataGrid columns={columns} rows={employees} onClick={e => setEmpId(e)}/>
            {open ? <CreateEmployee admin open={open} handleClose={handleClose}/> : null}
            {empId ? <EmployeeInfo admin empId={empId} open={Boolean(empId)} handleClose={clearEmpId}/> : null}
            {loading ? loader : null}
        </Box>

    );

};