import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { duty } from './gridData';
import { CustomizedDataGrid } from '../../components';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { 
    // Dialog, 
    Box, Backdrop, CircularProgress, Button, 
    // List, ListItem, OutlinedInput, FormControl, FormLabel, DialogTitle, DialogContent 
} from '@mui/material';
// import dayjs from 'dayjs';

const DutyList = ({projectId, empId, serviceId}) => {

    const [duties, setDuties] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    // const [open, setOpen] = React.useState(false);

    // const [Udata, setUData] = React.useState({
    //     duty: 0, 
    //     target: '', 
    //     date: null, 
    // });

    // const handleUData = React.useCallback((value) => {
    //     setUData(state => ({
    //         ...state, 
    //         ...value, 
    //     }));
    // }, [setUData]);

    // const findAndSet = (list, id) => {
    //     setUData({
    //         duty: 0, 
    //         target: '', 
    //         date: null, 
    //     });
    //     if(list){
    //         const len = list?.length || 0;
    //         if(len > 0){
    //             for(let i = 0; i < len; i++){
    //                 if(list[i].id === id){
    //                     setUData({
    //                         duty: list[i].duty, 
    //                         target: list[i].target, 
    //                         date: list[i].date, 
    //                     });
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    // };

    // const close = () => setOpen(false);

    const getData = async(projectId, empId, serviceId) => {
        setLoading(true);
        await axios.get('/get-employee-dutysheet', {
            params: {
                projectId: projectId, 
                empId: empId, 
                serviceId: serviceId, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => setDuties(res.data)).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get dutysheets. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    // const delDuty = React.useCallback(async(id) => {
    //     if(window.confirm('Are you sure you want to delete this duty?')){
    //         setLoading(true);
    //         await axios.post('/delete-dutysheet', { dutyId: id }, {
    //             headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
    //         }).then(() => {
    //             Swal.fire({
    //                 title: 'Deleted', 
    //                 text: 'Duty was deleted'
    //             });
    //         }).catch((e) => {
    //             Swal.fire({
    //                 title: 'Error', 
    //                 text: 'Error deleting duty. Check browser logs for more info'
    //             });
    //             console.log(`Error: ${e}`);
    //         });
    //         setLoading(false);
    //         close();
    //     }
    // }, []);

    // const handleSubmit = React.useCallback(async(e, dutyId) => {
    //     e.preventDefault();
    //     const data = new FormData(e.currentTarget);
    //     const params = {
    //         duty: data.get('duty'), 
    //         target: data.get('target'), 
    //         day: data.get('day'), 
    //         dutyId: dutyId, 
    //     };
    //     setLoading(true);
    //     await axios.post('/update-dutysheet', params, {
    //         headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
    //     }).then(() => {
    //         Swal.fire({
    //             title: 'Done', 
    //             text: 'Dutysheet was updated'
    //         });
    //     }).catch((e) => {
    //         Swal.fire({
    //             title: 'Error', 
    //             text: 'Error updating dutysheet. Check browser logs for more info'
    //         });
    //         console.log(`Error: ${e}`);
    //     });
    //     setLoading(false);
    //     close();
    // }, []);

    React.useEffect(() => {
        getData(projectId, empId, serviceId);
    }, [projectId, empId, serviceId]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress />
        </Backdrop>
    ), []);

    // const dialog = React.useMemo(() => (
    //     <Dialog open={open} onClose={close}
    //     PaperProps={{
    //         sx: {
    //           height: '650px', 
    //         }
    //     }}>
    //         <DialogTitle>Edit Duty</DialogTitle>
    //         <DialogContent sx={{ minWidth: 360 }}>
    //             <Box component='form' onSubmit={(e) => handleSubmit(e, open)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    //                 <List>
    //                     <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
    //                         <FormControl>
    //                             <FormLabel htmlFor="duty">Duty</FormLabel>
    //                             <OutlinedInput required margin="dense" id="duty" name="duty" label="Duty" placeholder="Duty" type="number" fullWidth inputProps={{ maxLength: 5 }} value={Udata.duty} onChange={e => handleUData({ duty: e.target.value })}/>
    //                         </FormControl>
    //                         <FormControl>
    //                             <FormLabel htmlFor="target">Target</FormLabel>
    //                             <OutlinedInput required margin="dense" id="target" name="target" label="Target" placeholder="Target" type="text" fullWidth inputProps={{ maxLength: 50 }} value={Udata.target} onChange={e => handleUData({ target: e.target.value })}/>
    //                         </FormControl>
    //                     </ListItem>
    //                     <ListItem>
    //                         <FormControl>
    //                             <FormLabel htmlFor="day">Date</FormLabel>
    //                             <LocalizationProvider dateAdapter={AdapterDayjs}>
    //                                 <DatePicker required id='day' name='day' slotProps={{ textField: { required: true } }} value={dayjs(Udata.date)} onChange={e => handleUData({ date: e })}/>
    //                             </LocalizationProvider>
    //                         </FormControl>
    //                     </ListItem>
    //                 </List>
    //                 <Box sx={{ flexDirection: 'row', mt: 2 }}>
    //                     <Button type='submit'>EDIT</Button>
    //                     <Button onClick={() => delDuty(open)}>DELETE</Button>
    //                     <Button onClick={close}>CANCEL</Button>
    //                 </Box>
    //             </Box>
    //         </DialogContent>
    //     </Dialog>
    // ), [open, handleSubmit, delDuty, Udata, handleUData]);

    return (

        <Box sx={{ width: '100%', height: '100%' }}>
            <Button variant="outlined" disabled={loading} onClick={() => getData(projectId, empId, serviceId)}>RELOAD</Button>
            <CustomizedDataGrid columns={duty} rows={duties} onClick={e => {
                // findAndSet(duties, e);
                // setOpen(e);
            }}/>
            {loading ? loader : null}
            {/* {open ? dialog : null} */}
        </Box>

    );

};

export default React.memo(DutyList);